import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { pofService } from '../services/pianoOffertaFormativaService';
import { ElForm } from 'element-ui/types/form';
import { formatTime } from '@/filters';
import moment, { relativeTimeThreshold } from 'moment';

@Component({})
export default class ManagePof extends Vue {
  @Prop({ required: true })
  periodoFormativo: periodoFormativo.item;

  @Prop({})
  pianiOffertaFormativa: pianoOffertaFormativa.item[];

  title: string = 'Inserimento nuovo Piano Offerta Formativa';
  manage: pianoOffertaFormativa.item = null;

  selectedRangeDates: Date[][] = [];
  created() {
    this.manage = {
      id: null,
      periodoFormativoId: this.periodoFormativo.id,
      nome: null,
      dataInizio: null,
      dataFine: null,
      attivo: false
    };

    if (this.pianiOffertaFormativa && this.pianiOffertaFormativa.length > 0) {
      this.pianiOffertaFormativa.forEach(f => {
        this.selectedRangeDates.push([f.dataInizio as Date, f.dataFine as Date]);
      });
    }
  }

  get formRules() {
    return {
      nome: [{ required: true, trigger: 'blur', message: `Il campo 'Nome' è obbligatorio` }],
      dataInizio: [{ required: true, trigger: 'blur', message: `Il campo 'Data inizio' è obbligatorio` }],
      dataFine: [{ validator: this.validateDataFine }]
    };
  }

  validateDataFine(rule, value, callback) {
    if (!value || value === '') {
      callback(new Error("Il campo 'Data fine' è obbligatorio"));
    } else if (value <= this.manage.dataInizio) {
      callback(new Error("Il campo 'Data fine' deve essere superiore alla 'Data inizio'"));
    } else {
      callback();
    }
  }

  get dataInizioPickerOptions() {
    const _self = this;
    return {
      disabledDate(time: Date) {
        const startPeriodo = _self.periodoFormativo.dataInizio;
        const endPeriodo = _self.periodoFormativo.dataFine;
        if (time.getFullYear() < startPeriodo || time.getFullYear() > endPeriodo) {
          return true;
        }

        if (_self.selectedRangeDates.length > 0) {
          for (const el of _self.selectedRangeDates) {
            var start = el[0];
            var end = el[1];

            if (time.getFullYear() <= end.getFullYear() && time.getMonth() <= end.getMonth() && time.getFullYear() >= start.getFullYear() && time.getMonth() >= start.getMonth()) {
              return true;
            }
          }
        }

        if (!_self.manage.dataFine) return false;
        return new Date(time.setDate(1)) > new Date(_self.manage.dataFine);
      }
    };
  }

  get dataFinePickerOptions() {
    const _self = this;
    return {
      disabledDate(time: Date) {
        const startPeriodo = _self.periodoFormativo.dataInizio;
        const endPeriodo = _self.periodoFormativo.dataFine;
        if (time.getFullYear() < startPeriodo || time.getFullYear() > endPeriodo) {
          return true;
        }

        if (!_self.manage.dataInizio) return false;
        return new Date(time.setDate(1)) <= new Date(_self.manage.dataInizio);
      }
    };
  }

  get computedTitle() {
    if (!this.manage || (this.manage && (!this.manage.dataInizio || !this.manage.dataFine))) return null;
    return `Piano offerta formativa ${formatTime(this.manage.dataInizio, 'MMMM yyyy')} - ${formatTime(this.manage.dataFine, 'MMMM yyyy')}`;
  }

  @Watch('computedTitle')
  dateRangeChange(n, o) {
    if (n && n !== o && (!this.manage.nome || this.manage.nome === o)) {
      this.manage.nome = n;
      (this.$refs.form as ElForm)?.validate();
    }
  }

  saving: boolean = false;
  async add() {
    (this.$refs.form as ElForm)?.validate(async valid => {
      if (valid) {
        this.saving = true;
        const exists = await pofService.Exist({
          dataInizio: this.manage.dataInizio,
          dataFine: this.manage.dataFine
        } as pianoOffertaFormativa.exist);
        if (exists) {
          this.$alert(`Il ${this.computedTitle} si sovrappone ad un Piano offerta formativa esistente`, 'Attenzione!', {
            confirmButtonText: 'Ok',
            type: 'warning',
            dangerouslyUseHTMLString: true
          });
          this.saving = false;
          return;
        }

        const dataInizio = new Date(this.manage.dataInizio);
        const end = new Date(this.manage.dataFine);

        const dataFine = new Date(end.setMonth(end.getMonth() + 1, 0));

        const pof: pianoOffertaFormativa.item = {
          id: null,
          periodoFormativoId: this.manage.periodoFormativoId,
          nome: `Piano offerta formativa ${formatTime(dataInizio, 'MMMM yyyy')} - ${formatTime(dataFine, 'MMMM yyyy')}`,
          dataInizio: formatTime(dataInizio, 'YYYY-MM-DD'),
          dataFine: formatTime(dataFine, 'YYYY-MM-DD'),
          attivo: false
        };

        pofService
          .Set(pof)
          .then(pof => {
            this.saving = false;
            this.$emit('change', pof);
            this.$message({
              message: `Il nuovo ${this.computedTitle} e' stato creato con successo`,
              dangerouslyUseHTMLString: true,
              type: 'success'
            });
          })
          .catch(err => {
            this.saving = false;
            this.$message({
              message: `Errore durante la creazione del ${this.computedTitle}`,
              type: 'error'
            });
          });
      }
    });
  }
}
