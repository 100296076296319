import { debounce } from '@/services/_base/debounce';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { pofService } from '../services/pianoOffertaFormativaService';
import { periodoFormativoService } from '../services/periodoFormativoService';
import ManagePof from '../components/managePof.vue';
import { store } from '@/store';

@Component({
  components: {
    ManagePof
  }
})
export default class pianoOffertaFormativa extends Vue {
  @Prop()
  periodoFormativoId?: string;

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  query: pianoOffertaFormativa.queryParams = {
    page: 1,
    take: 20,
    filter: null,
    periodoFormativoId: null
  };

 
  list: pianoOffertaFormativa.item[] = [];
  loading: boolean = false;
  showManage: boolean = false;

  get periodiFormativi(): periodoFormativo.item[]{
    return store.getters.periodiFormativi.list();
  }

  async created() {
    this.loading = true;
    if (this.periodoFormativoId) {
      this.query.periodoFormativoId = this.periodoFormativoId;
      await this.fetchData();
    }
    this.loading = false;
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await this.fetchData();
  }, 400);

  async fetchData() {
    try {
      this.loading = true;
      this.pagination.total = await pofService.Count(this.query);
      this.list = await pofService.List(this.query);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.$message({
        message: 'Errore durante il recupero dei Piani Offerta Formativi',
        duration: 5 * 1000,
        center: true,
        type: 'error'
      });
    }
  }

  get periodoFormativo() {
    if (!this.periodiFormativi || this.periodiFormativi.length <= 0) return null;
    return this.periodiFormativi.find(f => f.id === this.query.periodoFormativoId);
  }

  @Watch('query.periodoFormativoId')
  async queryChanged(n, o) {
    if (!n) {
      this.list = [];
      this.pagination.total = 0;
    } else {
      if (n !== o) {
        this.fetchData();
      }
    }
  }

  @Watch('query.filter')
  filterChanged(n, o) {
    if (n !== o) {
      this.fetchDataDebounced(this);
    }
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  onChange(pof: pianoOffertaFormativa.item) {
    this.fetchData();
    this.showManage = false;
  }

  async handleChangeStato(row: pianoOffertaFormativa.item) {
    const result = await pofService.Set(row);
    if (result) {
      row.attivo = result.attivo;
    }
    else {
      row.attivo = !row.attivo;
    }
  }

  async handleRemove(row: periodoFormativo.item) {
    this.$msgbox({
      title: `Attenzione`,
      message: `Il piano offerta formativa <strong>${row.nome}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(async () => {
        try {
          await pofService.Remove(row.id);
          this.fetchData();
          this.$message({
            message: `Eliminazione del piano offerta formativa <strong>${row.nome}</strong> effettuato con successo.`,
            dangerouslyUseHTMLString: true,
            duration: 5 * 1000,
            center: true,
            showClose: true,
            type: 'success'
          });
        } catch (err) {
          this.$message({
            message: "Errore durante l'eliminazione del piano offerta formativa",
            duration: 5 * 1000,
            center: true,
            showClose: true,
            type: 'error'
          });
        }
      })
      .catch(err => {
        console.info(err);
      });
  }
}
